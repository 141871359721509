import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import approve from 'approvejs';
import { useLocation } from 'react-router-dom';

import Card, { CardBody } from '../../components/Card';
import { getTranslationKey } from '../../modules/utils';
import InputGroup from '../Forms/InputGroup';
import Select from '../Forms/Select';
import { getCountriesList } from '../../modules/selectors/countries';
import { fetchCountriesAction } from '../../modules/actions/countries';
import { ICountry, IDictionary } from '../../modules/types';
import { showNotifyFail, updateBillingAddress } from '../../modules/actions';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { getUser } from '../../modules/selectors';
import { IAdressInterface } from '../../modules/types/paymentInfo';

interface IProps {
  isOpen: boolean;
  toggle: () => void;
}

interface Ifields {
  field: string;
  type: string;
  placeholder: string;
  title: string;
  rules: any;
}

interface seperateStreetFieldResponse {
  street: string | null;
  streetNumber: string | null;
}

const emailField: Ifields = {
  field: 'email',
  type: 'email',
  placeholder: getTranslationKey('email'),
  title: `${getTranslationKey('email')}*`,
  rules: {
    required: { message: getTranslationKey('requiredInput') },
    email: {
      message: getTranslationKey('requiredEMailInput')
    }
  }
};

const companyField: Ifields = {
  field: 'company',
  type: 'text',
  placeholder: getTranslationKey('company'),
  title: `${getTranslationKey('company')}*`,
  rules: {
    required: { message: getTranslationKey('requiredInput') }
  }
};

const col6Fields: Ifields[] = [
  {
    field: 'firstName',
    type: 'text',
    placeholder: getTranslationKey('firstname'),
    title: `${getTranslationKey('firstname')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  {
    field: 'lastName',
    type: 'text',
    placeholder: getTranslationKey('lastname'),
    title: `${getTranslationKey('lastname')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  {
    field: 'street',
    type: 'text',
    placeholder: getTranslationKey('street'),
    title: `${getTranslationKey('street')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  {
    field: 'streetNumber',
    type: 'text',
    placeholder: getTranslationKey('streetNumber'),
    title: `${getTranslationKey('streetNumber')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  {
    field: 'zipcode',
    type: 'text',
    placeholder: getTranslationKey('zipcode'),
    title: `${getTranslationKey('zipcode')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  {
    field: 'city',
    type: 'text',
    placeholder: getTranslationKey('city'),
    title: `${getTranslationKey('city')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  }
];

const getCountriesOptions = (list: ICountry[]) =>
  list.map((country) => ({
    value: country.id,
    displayValue: country.name
  }));

const BillingAddressModalComponent: React.FunctionComponent<IProps & InjectedFormProps> = ({
  isOpen,
  toggle,
  handleSubmit,
  initialize,
  submitting
}) => {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const countriesList = useSelector(getCountriesList);
  const countriesOptions = getCountriesOptions(countriesList);

  const countryField = {
    field: 'country',
    options: countriesOptions,
    title: `${getTranslationKey('shop.taxConfig.locationLabel')}*`,
    labelClass: 'font-weight-bold'
  };

  const location = useLocation();

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  const {
    address: billingaddress = {
      city: '',
      company: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      zipcode: '',
      streetNumber: ''
    }
  }: { address: IAdressInterface } = useSelector(getPaymentInfo);

  const userAuth = useSelector(getUser);

  const seperateStreetField = (street: string): seperateStreetFieldResponse => {
    const streetArr = street.match(/(\D+)\s+(\d+)/);

    return {
      street: streetArr && streetArr[1] ? streetArr[1] : street,
      streetNumber: streetArr && streetArr[2] ? streetArr[2] : null
    };
  };

  useEffect(() => {
    initialize({
      company: billingaddress.company || userAuth.company,
      email: billingaddress.email || userAuth.email,
      country: billingaddress.country || userAuth._country,
      city: billingaddress.city || userAuth.city,
      firstName: billingaddress.firstName || userAuth.first_name,
      lastName: billingaddress.lastName || userAuth.last_name,
      street: billingaddress.street || seperateStreetField(userAuth.street).street,
      streetNumber:
        billingaddress.streetNumber || seperateStreetField(userAuth.street).streetNumber,
      zipcode: billingaddress.zipcode || userAuth.zipcode
    });
  }, [billingaddress]); // eslint-disable-line

  const submitAddress = (values: IDictionary<string>) => {
    try {
      setSubmitted(true);
      dispatch(
        updateBillingAddress({
          email: values.email,
          country: values.country,
          firstName: values.firstName,
          lastName: values.lastName,
          company: values.company,
          street: values.street,
          streetNumber: values.streetNumber,
          zipcode: values.zipcode,
          city: values.city,
          routerPath: location.pathname
        })
      );

      toggle();
      setSubmitted(false);
    } catch (e) {
      setSubmitted(false);
      dispatch(showNotifyFail());
    }
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-dark" size="md">
      <form onSubmit={handleSubmit(submitAddress)}>
        <ModalHeader toggle={toggle}>
          <i className="nav-icon icon-user mr-1" /> {getTranslationKey('billingAddressModalTitle')}
        </ModalHeader>
        <ModalBody style={{ padding: '1rem 0 0 0' }}>
          <div className="animated fadeIn">
            <Row>
              <Col sm="12" md="12" lg="12">
                <Card style={{ paddingBottom: '0', paddingTop: '0', marginBottom: '0' }}>
                  <CardBody>
                    {location.pathname !== '/subscription' && (
                      <Row className="mb-3">
                        <Col xs="12" lg="12">
                          {getTranslationKey('billingAddressModalInfo')}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs="12" lg="12">
                        <Field
                          component={InputGroup}
                          name={emailField.field}
                          field={emailField}
                          normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Field component={Select} name={countryField.field} field={countryField} />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <Field
                          component={InputGroup}
                          name={companyField.field}
                          field={companyField}
                          normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {col6Fields.map((field, index) => (
                        <Col key={index} sm="12" md="6">
                          <Field
                            component={InputGroup}
                            name={field.field}
                            field={field}
                            normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                          />
                        </Col>
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col xs="12">
              <Button
                color="primary"
                className="w-100-p"
                disabled={submitting || submitted}
                type="submit"
              >
                {submitted
                  ? getTranslationKey('buttonLoading')
                  : location.pathname === '/subscription'
                  ? getTranslationKey('save')
                  : getTranslationKey('subscription.buyNow')}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </form>
    </Modal>
  );
};

function validate(values: any) {
  const errors: IDictionary<string> = {};

  const emailResult = approve.value(values[emailField.field], emailField.rules);
  if (emailResult.errors.length > 0) {
    errors[emailField.field] = emailResult.errors;
  }

  const companyResult = approve.value(values[companyField.field], companyField.rules);
  if (companyResult.errors.length > 0) {
    errors[companyField.field] = companyResult.errors;
  }

  col6Fields.forEach((field) => {
    const result = approve.value(values[field.field], field.rules);
    if (result.errors.length > 0) {
      errors[field.field] = result.errors;
    }
  });

  return errors;
}

export const BillingAddressModal = reduxForm<IProps, any>({
  form: 'billindAddressForm',
  validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(BillingAddressModalComponent);

export default BillingAddressModal;
