import React, { useState } from 'react';
import { getHTMLFromText } from '../../../../../helpers/html';
import { getTranslationKey } from '../../../../../modules/utils/helpers/index';
import { StyledTr, StyledTd } from '../../index';
import { IAdressInterface } from '../../../../../modules/types/paymentInfo';
import styled from 'styled-components';
import useTrackClick from '../../../../../hooks/tracking/use-track-click';
import BillingAddressModal from '../../../../../elements/Subscription/BillingAddressModal';
import { BillingAddressChangeModal } from '../../../../../elements/Subscription/BillingAddressChangeModal';

interface IBillingInfo {
  address: IAdressInterface;
  plan: string;
}

const AddressBox = styled.div``;
const EditBillingButton = styled.button`
  border: 0 none;
  background-color: white;
  color: #5c8762;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:not(:hover) {
    text-decoration: none;
  }
`;

const BillingInfo = ({ address, plan }: IBillingInfo) => {
  const [isBillingAddressModalOpen, setisBillingAddressModalOpen] = useState(false);
  const trackClick = useTrackClick();
  const {
    email = '',
    firstName = '',
    lastName = '',
    // company = '',
    street = '',
    streetNumber = '',
    zipcode = '',
    city = '',
    country = ''
  } = address;

  const isFilled = Object.values(address).some(Boolean);
  const freePlans = ['free', 'free-trial', 'freemium'];
  const isPaidPlan = !freePlans.includes(plan);

  if (!isPaidPlan || !isFilled) {
    return null;
  }

  const onEditBillingInfoClick = () => {
    setisBillingAddressModalOpen(true);
    trackClick('edit-billing');
  };

  return (
    <>
      <StyledTr disableLine>
        <StyledTd>{getHTMLFromText(getTranslationKey('overviewTab.billingInfo'))}</StyledTd>
        <StyledTd>
          <EditBillingButton
            onClick={(e) => {
              e.preventDefault();
              onEditBillingInfoClick();
            }}
          >
            {getTranslationKey('overviewTab.editBilling')}
          </EditBillingButton>
        </StyledTd>
      </StyledTr>

      <StyledTr disableLine>
        <StyledTd paddingTop={0}>
          {getHTMLFromText(getTranslationKey('collectionSettings.sendEmail.email'))}{' '}
          <span>{email}</span>
        </StyledTd>
        <StyledTd paddingTop={0} />
      </StyledTr>

      <StyledTr>
        <StyledTd paddingTop={0}>
          <AddressBox>
            {getTranslationKey('venue.address')}:
            <div>
              {firstName} {lastName}
            </div>
            {street && (
              <div>
                {street} {streetNumber && streetNumber}
              </div>
            )}
            {(zipcode || city) && (
              <div>
                {zipcode} {city}
              </div>
            )}
            {country && <div>{country}</div>}
          </AddressBox>
        </StyledTd>
        <StyledTd paddingTop={0} />
      </StyledTr>
      <BillingAddressModal
        isOpen={isBillingAddressModalOpen}
        toggle={() => setisBillingAddressModalOpen(!isBillingAddressModalOpen)}
      />
      <BillingAddressChangeModal />
    </>
  );
};

export default BillingInfo;
