import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { get } from 'lodash';
import queryString from 'query-string';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import theme from '../../../assets/css/theme';
import {
  fetchPaymentInfoAction,
  fetchPricingAction,
  getProrationsAction,
  setupIntentFailed,
  showNotifyFail,
  updateSubscriptionStore
} from '../../../modules/actions';
import { useSubscriptionCurrency } from '../../../modules/hooks/useSubscriptionCurrency';
import { getUser } from '../../../modules/selectors';
import { getPaymentInfo, getIsSepaPending } from '../../../modules/selectors/paymentInfo';
import { IGetProrationsParams } from '../../../modules/types/subscription';
import { HTTPClient, getTranslationKey } from '../../../modules/utils';
import { useSubscriptionBreakdown } from '../helpers/useSubscriptionBreakdown';
import { useLocation } from 'react-router';
import { isInvalid } from 'redux-form';
import { formatPrice } from '../helpers/format-price';
import { getCountriesList } from '../../../modules/selectors/countries';
import { breakpoints } from '../../../hooks/useBreakpoint';
import qs from 'querystring';
import { fetchCountriesAction } from '../../../modules/actions/countries';
import { getSubscription } from '../../../modules/selectors/subscription';
import moment from 'moment';

const APIClient = new HTTPClient({
  baseURL: window.API_URL,
  withCredentials: true
});

const BreakdownItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${theme.commonColors.secondBackground};
  margin: 10px 0;
`;

const BreakdownWrapper = styled.div`
  margin: 10px 0;
`;

const Title = styled.div`
  margin: 0 0 20px 0;
  font-weight: bolder;
  font-size: 30px;
`;

const SubscriptionPaymentElementWrapper = ({ children }: PropsWithChildren) => (
  <>
    <Title>{getTranslationKey('subscription.paymentTitle')}</Title>
    {children}
  </>
);

function SubscriptionPaymentElement({
  submitForm,
  isSubmit,
  billingFormIsInvalid,
  billingFormHasUnsavedChanges
}: {
  submitForm: () => void;
  isSubmit: boolean;
  billingFormIsInvalid: boolean;
  billingFormHasUnsavedChanges: boolean;
}) {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const location = useLocation();
  const elements = useElements();
  const breakdown = useSubscriptionBreakdown();
  const { plan, billingCycle, prorations, ...subscriptionBreakdown } = breakdown;
  const { videoStorage, templates, slideshows, brandsAmount, domainsAmount } =
    useSelector(getSubscription);
  const { currencySymbol, subscriptionCurrency } = useSubscriptionCurrency();
  const user = useSelector(getUser);
  const discountKeyId = get(user, 'digistore.discountKey._id', '');
  const {
    address: billingAddress,
    subscriptions,
    userId,
    paymentProvider,
    pricing: { loading: pricingIsLoading }
  } = useSelector(getPaymentInfo);
  const isSepaPending = useSelector(getIsSepaPending);
  const countriesList = useSelector(getCountriesList);
  const searchParams = qs.parse(location.search.substring(1));
  const setupFailed =
    searchParams.setup_failed || user.subscription.state === 'payment_setup_problem';

  const billindDataIsFilled =
    !!billingAddress.city &&
    !!billingAddress.company &&
    !!billingAddress.country &&
    !!billingAddress.email &&
    !!billingAddress.firstName &&
    !!billingAddress.lastName &&
    !!billingAddress.street &&
    !!billingAddress.zipcode &&
    !!billingAddress.streetNumber &&
    (setupFailed ? true : !billingFormIsInvalid && !!user.taxSettings.vatIdIsValid);
  const billingCountry = countriesList.find((country) => country.id === billingAddress.country);

  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [updatePaymentInfoRegularly, setUpdatePaymentInfoRegularly] = useState(false);
  const [userWantsToConfirmPayment, setUserWantsToConfirmPayment] = useState(false);

  useEffect(() => {
    dispatch(fetchPricingAction());
    dispatch(fetchPaymentInfoAction());

    const storedSubscriptionConfig = window.localStorage.getItem('subscriptionConfig');
    if (storedSubscriptionConfig) {
      const subscriptionConfig = JSON.parse(storedSubscriptionConfig);
      dispatch(updateSubscriptionStore(subscriptionConfig));
    }

    if (window.innerWidth < breakpoints.mobile) window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  useEffect(() => {
    if (!!searchParams.payment_failed) {
      setErrorMessage(getTranslationKey('subscription.paymentFailed'));
    }
    if (!!setupFailed) {
      setErrorMessage(getTranslationKey('subscription.setupFailed', { currency: currencySymbol }));
      localStorage.setItem('payment_setup_failed', 'true');
      localStorage.setItem('payment_setup_query_params', location.search);
    }
  }, [searchParams]);

  const isCorePaymentProblem = subscriptions?.some(
    (sub) => sub.subscriptionType === 'core' && sub.isPaymentProblem
  );
  const userHasCoreSubscription = subscriptions?.some((sub) => sub.subscriptionType === 'core');
  const userHasSlideshowSubscription = subscriptions?.some(
    (sub) => sub.subscriptionType === 'slideshow'
  );

  const userIsStarterCustomerWithoutDomainSubscription =
    subscriptions?.some(
      (sub) => sub.subscriptionType === 'core' && sub.productName?.toLowerCase().includes('starter')
    ) && !subscriptions?.some((sub) => sub.subscriptionType === 'domain');

  const userIsActiveCustomer =
    userHasCoreSubscription && (paymentProvider === 'stripe' ? !isCorePaymentProblem : true);
  const userIsStripeCustomer =
    !isCorePaymentProblem &&
    !!subscriptions?.find((sub) => sub.subscriptionType === 'core' && !!sub.stripeSubscriptionId);
  const showPaymentElement =
    user._id && userId && (!userIsStripeCustomer || isCorePaymentProblem || setupFailed);
  const subscriptionConfig: IGetProrationsParams['subscriptionConfig'] = {
    billingCycle,
    core: {
      plan: subscriptionBreakdown.corePlan.plan,
      priceId: subscriptionBreakdown.corePlan.priceId
    }
  };
  const oneTimePurchases = subscriptionBreakdown.templates.map((template) => ({
    price: template.priceId,
    id: template.templateId
  }));

  if (subscriptionBreakdown.slideshow) {
    subscriptionConfig.slideshows = {
      plan: subscriptionBreakdown.slideshow.plan,
      priceId: subscriptionBreakdown.slideshow.priceId
    };
  }

  if (subscriptionBreakdown.videoStorage) {
    subscriptionConfig['video-storage'] = {
      plan: subscriptionBreakdown.videoStorage.plan,
      priceId: subscriptionBreakdown.videoStorage.priceId
    };
  }

  if (subscriptionBreakdown.brands) {
    subscriptionConfig.brands = {
      priceId: subscriptionBreakdown.brands.priceId,
      quantity: subscriptionBreakdown.brands.quantity
    };
  }

  if (subscriptionBreakdown.domains) {
    subscriptionConfig.domains = {
      priceId: subscriptionBreakdown.domains.priceId,
      quantity: subscriptionBreakdown.domains.quantity
    };
  }

  useEffect(() => {
    if (
      !setupFailed &&
      userIsStripeCustomer &&
      !isCorePaymentProblem &&
      subscriptionBreakdown.corePlan.priceId
    ) {
      dispatch(
        getProrationsAction({
          subscriptionConfig,
          oneTimePurchases
        })
      );
    }
  }, [
    setupFailed,
    userIsStripeCustomer,
    paymentProvider,
    subscriptionBreakdown.corePlan.priceId,
    subscriptionBreakdown.slideshow?.priceId,
    subscriptionBreakdown.videoStorage?.priceId,
    subscriptionBreakdown.brands?.priceId,
    subscriptionBreakdown.brands?.quantity,
    subscriptionBreakdown.domains?.priceId,
    subscriptionBreakdown.domains?.quantity,
    subscriptionBreakdown.totalGross,
    user.taxSettings.platformPercent
  ]);

  const subscriptionRef = useRef(subscriptions);
  const returnUrlParams = {
    coupon_code: discountKeyId,
    currency: subscriptionCurrency,
    earned_amount: subscriptionBreakdown.totalNet,
    product_name: subscriptionBreakdown.corePlan.displayName,
    product_id: subscriptionBreakdown.corePlan.productId,
    quantity: 1,
    isTest:
      process.env.NODE_ENV !== 'production' ||
      user.builderDomain.includes('builder-dev') ||
      user.builderDomain.includes('builder-staging')
  };

  const handleError = (error: any) => {
    setLoading(false);
    setUpdatePaymentInfoRegularly(false);
    setErrorMessage(error.message);
  };

  useEffect(() => {
    // Once user confirmed changes to subscription we need to wait for invoice to get through
    // And then either show an error or redirect to thanks page
    let timeoutId: any;
    if (updatePaymentInfoRegularly) {
      const coreSub = subscriptions?.find(
        (subscription) => subscription.subscriptionType === 'core'
      );
      const prevCoreSub = subscriptionRef.current?.find(
        (subscription) => subscription.subscriptionType === 'core'
      );
      const coreSubChanged =
        coreSub && prevCoreSub && coreSub.lastInvoiceId !== prevCoreSub.lastInvoiceId;

      if (coreSubChanged) {
        setLoading(false);
        if (coreSub.isPaymentProblem) {
          setErrorMessage(getTranslationKey('subscription.paymentFailed'));
        } else {
          const finalThanksPageUrl = `https://${user.domain}/thanks?${queryString.stringify({
            ...returnUrlParams,
            order_id: coreSub.lastInvoiceId,
            order_item_id: coreSub.lastInvoiceId
          })}`;

          if (
            userIsStarterCustomerWithoutDomainSubscription &&
            process.env.REACT_APP_DOMAIN_UPSELL_PAGE
          ) {
            window.location.href = `${
              process.env.REACT_APP_DOMAIN_UPSELL_PAGE
            }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(finalThanksPageUrl)}`;
          } else if (!userHasSlideshowSubscription && process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE) {
            window.location.href = `${
              process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE
            }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(finalThanksPageUrl)}`;
          } else {
            window.location.href = finalThanksPageUrl;
          }
        }
      } else {
        timeoutId = setTimeout(() => {
          dispatch(fetchPaymentInfoAction());
        }, 2000);
      }
    }

    subscriptionRef.current = subscriptions;

    return () => {
      clearTimeout(timeoutId);
    };
  }, [updatePaymentInfoRegularly, subscriptions]);

  const billingDetails = {
    name: billingAddress.firstName + ' ' + (billingAddress.lastName || ''),
    email: billingAddress.email,
    address: {
      country: billingCountry?.code,
      postal_code: billingAddress.zipcode,
      state: '',
      city: billingAddress.city,
      line1: billingAddress.company,
      line2: `${billingAddress.street} ${billingAddress.streetNumber}`
    }
  };

  const [confirmSubscriptionChangeParams, setConfirmSubscriptionChangeParams] = useState({
    clientSecret: '',
    invoiceId: '',
    paymentMethodId: ''
  });

  const confirmPayment = async () => {
    setUserWantsToConfirmPayment(false);
    setLoading(true);

    if (userIsStripeCustomer && !isCorePaymentProblem) {
      let clientSecret;
      let invoiceId;
      let paymentMethodId;
      if (
        confirmSubscriptionChangeParams.clientSecret &&
        confirmSubscriptionChangeParams.invoiceId &&
        confirmSubscriptionChangeParams.paymentMethodId
      ) {
        clientSecret = confirmSubscriptionChangeParams.clientSecret;
        invoiceId = confirmSubscriptionChangeParams.invoiceId;
        paymentMethodId = confirmSubscriptionChangeParams.paymentMethodId;
      } else {
        const res = await APIClient.patch('/api/subscription', {
          prorationDate: prorations?.prorationDate,
          subscriptionConfig,
          oneTimePurchases
        });

        clientSecret = res.result.paymentIntentClientSecret;
        invoiceId = res.result.invoiceId;
        paymentMethodId = res.result.paymentMethodId;

        setConfirmSubscriptionChangeParams({
          clientSecret,
          invoiceId,
          paymentMethodId
        });
      }

      if (clientSecret && invoiceId && paymentMethodId) {
        let returnUrl = `https://${user.domain}/thanks?${queryString.stringify({
          ...returnUrlParams,
          order_id: invoiceId,
          order_item_id: invoiceId
        })}`;

        if (
          userIsStarterCustomerWithoutDomainSubscription &&
          process.env.REACT_APP_DOMAIN_UPSELL_PAGE
        ) {
          returnUrl = `${
            process.env.REACT_APP_DOMAIN_UPSELL_PAGE
          }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(returnUrl)}`;
        } else if (!userHasSlideshowSubscription && process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE) {
          returnUrl = `${
            process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE
          }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(returnUrl)}`;
        }

        const confirmParams = {
          return_url: `${window.API_URL}/api/subscription/stripe-payment-complete?builder_domain=${
            user.builderDomain
          }&return_url=${encodeURIComponent(returnUrl)}`,
          payment_method: paymentMethodId
        };

        const { error } = await stripe.confirmPayment({
          clientSecret,
          confirmParams
        });

        if (error) {
          handleError(error);
        }
      } else {
        setUpdatePaymentInfoRegularly(true);
      }

      return;
    }

    setUpdatePaymentInfoRegularly(true);

    if (!stripe) {
      setLoading(false);

      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      setUpdatePaymentInfoRegularly(false);

      return;
    }

    // Create the subscription

    const res = await APIClient.post('/api/subscription/initiate-new-subscription', {
      paymentMethod: null,
      subscriptionItems: [
        { type: 'core', price: subscriptionBreakdown.corePlan.priceId },
        { type: 'slideshow', price: subscriptionBreakdown.slideshow?.priceId },
        { type: 'video', price: subscriptionBreakdown.videoStorage?.priceId },
        {
          type: 'brand',
          price: subscriptionBreakdown.brands?.priceId,
          quantity: subscriptionBreakdown.brands?.quantity
        },
        {
          type: 'domain',
          price: subscriptionBreakdown.domains?.priceId,
          quantity: subscriptionBreakdown.domains?.quantity
        }
      ].filter((item) => !!item.price),
      oneTimeItems: subscriptionBreakdown.templates.map((template) => template.priceId),
      subscriptionConfig: {
        plan,
        billingCycle,
        videoStorage,
        slideshows,
        templates: templates || [],
        brandsAmount,
        domainsAmount
      },
      prorationDate: prorations.prorationDate || Math.floor(Date.now() / 1000)
    });
    setUpdatePaymentInfoRegularly(false);
    if (res.code === 400 && res.response?.data?.error?.name === 'VatIdIsNotValid') {
      const errorMessage = getTranslationKey('settingsSection.vatValidationError');
      dispatch(showNotifyFail({ message: errorMessage }));
      setLoading(false);

      return;
    }
    const { clientSecret, invoiceId, paid } = await res.result;

    let thanksPageUrl = `https://${user.domain}/thanks?${queryString.stringify({
      ...returnUrlParams,
      order_id: invoiceId,
      order_item_id: invoiceId
    })}`;

    if (plan === 'starter' && domainsAmount === 0 && process.env.REACT_APP_DOMAIN_UPSELL_PAGE) {
      thanksPageUrl = `${
        process.env.REACT_APP_DOMAIN_UPSELL_PAGE
      }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(thanksPageUrl)}`;
    } else if (!slideshows && process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE) {
      thanksPageUrl = `${
        process.env.REACT_APP_SLIDESHOW_UPSELL_PAGE
      }/?billingCycle=${billingCycle}&thankYouUrl=${encodeURIComponent(thanksPageUrl)}`;
    }

    const returnUrl = `${window.API_URL}/api/subscription/stripe-payment-complete?builder_domain=${
      user.builderDomain
    }&return_url=${encodeURIComponent(thanksPageUrl)}`;

    const confirmParams = {
      return_url: returnUrl,
      payment_method_data: {
        billing_details: billingDetails
      }
    };

    if (paid) {
      const { error } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          ...confirmParams
        }
      });

      if (error) {
        dispatch(
          setupIntentFailed({
            clientSecret,
            returnUrl: thanksPageUrl
          })
        );
        handleError(error);
        console.log({ error });
      }

      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams
    });

    if (error) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to the customer (for example, "payment details incomplete").
      handleError(error);
    } else {
      // Customer is redirected to the `return_url`. For some payment
      // methods like iDEAL, the customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const confirmFailedSetup = async () => {
    setUserWantsToConfirmPayment(false);
    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);

      return;
    }

    const clientSecret =
      (searchParams.client_secret as string) || user.subscription.stripe.paymentSetup.clientSecret;
    const returnUrl =
      (searchParams.return_url as string) || user.subscription.stripe.paymentSetup.returnUrl;

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.API_URL}/api/subscription/stripe-payment-complete?builder_domain=${
          user.builderDomain
        }&return_url=${encodeURIComponent(returnUrl)}`,
        payment_method_data: {
          billing_details: billingDetails
        }
      }
    });

    if (error) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to the customer (for example, "payment details incomplete").
      handleError(error);
    }
  };

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setUserWantsToConfirmPayment(true);
    if (!setupFailed) submitForm();
  };
  useEffect(() => {
    if (billingFormHasUnsavedChanges) {
      return;
    }

    if (userWantsToConfirmPayment && billindDataIsFilled && setupFailed) {
      confirmFailedSetup();
    } else if (userWantsToConfirmPayment && stripe && !loading && billindDataIsFilled && isSubmit) {
      confirmPayment();
    }
  }, [
    userWantsToConfirmPayment,
    setupFailed,
    stripe,
    loading,
    billindDataIsFilled,
    isSubmit,
    billingFormHasUnsavedChanges
  ]);

  const billingCycleTranslation = getTranslationKey(`subscription.${billingCycle}Payments`);
  const totalGrossCents = subscriptionBreakdown.totalGross * 100;
  const amountDue = Math.max(prorations.upcommingInvoiceAmountCents, 0);
  const remainingBalance = Math.max(prorations.remainingBalanceCents, 0);
  const balance = amountDue < totalGrossCents ? -Math.floor(totalGrossCents - amountDue) / 100 : 0;
  const userCanMigrateToStripe = breakdown.remainingDigistoreBalance === 0;
  const totalGrossIsTooSmall = subscriptionBreakdown.totalGross > 0 && totalGrossCents < 50;

  let nextPaymentDate = subscriptions.find((sub) => sub.subscriptionType === 'core')?.nextPaymentAt;
  if (nextPaymentDate) {
    nextPaymentDate = moment(nextPaymentDate).format('DD.MM.YYYY');
  }

  if (pricingIsLoading) {
    return <SubscriptionPaymentElementWrapper />;
  }

  if (isSepaPending) {
    return (
      <SubscriptionPaymentElementWrapper>
        <p>{getTranslationKey('subscription.sepa-pending')}</p>
      </SubscriptionPaymentElementWrapper>
    );
  }

  if (!userCanMigrateToStripe) {
    if (paymentProvider === 'stripe') {
      return (
        <SubscriptionPaymentElementWrapper>
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslationKey('subscription.user-cant-change-sub-balance', {
                nextPaymentDateCoreSubscription: nextPaymentDate
              })
            }}
          />
        </SubscriptionPaymentElementWrapper>
      );
    }

    return (
      <SubscriptionPaymentElementWrapper>
        <p
          dangerouslySetInnerHTML={{
            __html: getTranslationKey('subscription.user-cant-migrate-to-stripe', {
              nextPaymentDateCoreSubscription: nextPaymentDate
            })
          }}
        />
      </SubscriptionPaymentElementWrapper>
    );
  }

  return (
    <SubscriptionPaymentElementWrapper>
      {!setupFailed && (
        <BreakdownWrapper>
          {!!subscriptionBreakdown.corePlan.price && (
            <BreakdownItem>
              <span>
                {subscriptionBreakdown.corePlan.displayName} {billingCycleTranslation}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(subscriptionBreakdown.corePlan.price),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          {!!subscriptionBreakdown.slideshow?.price && (
            <BreakdownItem>
              <span>
                {subscriptionBreakdown.slideshow.displayName} {billingCycleTranslation}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(subscriptionBreakdown.slideshow.price),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          {!!subscriptionBreakdown.videoStorage?.price && (
            <BreakdownItem>
              <span>
                {subscriptionBreakdown.videoStorage.displayName} {billingCycleTranslation}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(subscriptionBreakdown.videoStorage.price),
                  currency: currencySymbol
                })}{' '}
              </span>
            </BreakdownItem>
          )}
          {subscriptionBreakdown.brands?.price > 0 && (
            <BreakdownItem>
              <span>
                {subscriptionBreakdown.brands.displayName} {billingCycleTranslation}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(subscriptionBreakdown.brands.price),
                  currency: currencySymbol
                })}{' '}
              </span>
            </BreakdownItem>
          )}
          {subscriptionBreakdown.domains?.price > 0 && (
            <BreakdownItem>
              <span>
                {subscriptionBreakdown.domains.displayName} {billingCycleTranslation}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(subscriptionBreakdown.domains.price),
                  currency: currencySymbol
                })}{' '}
              </span>
            </BreakdownItem>
          )}
          {subscriptionBreakdown.templates.map((template, key) => (
            <BreakdownItem key={key}>
              <span>
                {template.displayName} {getTranslationKey('subscription.oneTime')}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(template.price),
                  currency: currencySymbol
                })}{' '}
              </span>
            </BreakdownItem>
          ))}
          {subscriptionBreakdown.discount > 0 && (
            <BreakdownItem>
              <span>{getTranslationKey('subscription.discount')}</span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(-subscriptionBreakdown.discount),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          {userIsStripeCustomer && breakdown.prorations.prorationCents < 0 && (
            <BreakdownItem>
              <span>{getTranslationKey('subscription.balance')}</span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(breakdown.prorations.prorationCents / 100),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          {subscriptionBreakdown.digistoreBalance > 0 && (
            <BreakdownItem>
              <span>
                {getTranslationKey('subscription.balance')}
                {subscriptionBreakdown.remainingDigistoreBalance > 0 ? '*' : ''}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(-subscriptionBreakdown.digistoreBalance),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          <Divider />
          <BreakdownItem>
            <span>{getTranslationKey('subscription.totalNet')}</span>
            <span>
              {getTranslationKey('priceWithCurrency', {
                price: formatPrice(subscriptionBreakdown.totalNet),
                currency: currencySymbol
              })}
            </span>
          </BreakdownItem>
          <BreakdownItem>
            <span>
              {getTranslationKey('subscription.paymentVat')} {user.taxSettings.platformPercent}%
            </span>
            <span>
              {getTranslationKey('priceWithCurrency', {
                price: formatPrice(subscriptionBreakdown.tax),
                currency: currencySymbol
              })}
            </span>
          </BreakdownItem>
          {userIsStripeCustomer && balance < 0 && (
            <BreakdownItem>
              <span>
                {getTranslationKey('subscription.balance')}
                {remainingBalance > 0 ? '*' : ''}
              </span>
              <span>
                {getTranslationKey('priceWithCurrency', {
                  price: formatPrice(balance),
                  currency: currencySymbol
                })}
              </span>
            </BreakdownItem>
          )}
          <BreakdownItem>
            <span>{getTranslationKey('subscription.totalGross')}</span>
            <span>
              {getTranslationKey('priceWithCurrency', {
                price: formatPrice(
                  userIsStripeCustomer ? amountDue / 100 : subscriptionBreakdown.totalGross
                ),
                currency: currencySymbol
              })}
            </span>
          </BreakdownItem>
        </BreakdownWrapper>
      )}
      {showPaymentElement && userCanMigrateToStripe && (
        <PaymentElement
          options={{
            defaultValues: {
              billingDetails: {
                name: billingAddress.firstName + ' ' + (billingAddress.lastName || ''),
                email: billingAddress.email,
                address: {
                  country: billingCountry?.code,
                  postal_code: billingAddress.zipcode,
                  city: billingAddress.city,
                  line1: billingAddress.company,
                  line2: `${billingAddress.street} ${billingAddress.streetNumber}`
                }
              }
            },
            fields: {
              billingDetails: {
                name: 'never',
                email: 'never',
                address: 'never'
              }
            }
          }}
        />
      )}
      {totalGrossIsTooSmall && (
        <p
          className="mb-2 mt-3"
          dangerouslySetInnerHTML={{
            __html: getTranslationKey('subscription.total-gross-too-small', {
              price: formatPrice(0.5),
              currency: currencySymbol
            })
          }}
        />
      )}
      <Button
        color="primary"
        className="w-100-p mt-3"
        onClick={handleSubmit}
        disabled={
          !stripe ||
          loading ||
          prorations.loading ||
          !user._id ||
          !userId ||
          subscriptionBreakdown.pricingIsLoading ||
          !userCanMigrateToStripe ||
          totalGrossIsTooSmall ||
          user.isUpdating
        }
        type="submit"
      >
        {loading
          ? getTranslationKey('buttonLoading')
          : getTranslationKey(userIsActiveCustomer ? 'subscription.confirm' : 'subscription.pay')}
      </Button>
      {remainingBalance > 0 && (
        <BreakdownItem>
          <span>
            *{getTranslationKey('subscription.remaining-balance')}:{' '}
            {getTranslationKey('priceWithCurrency', {
              price: formatPrice(remainingBalance / 100),
              currency: currencySymbol
            })}
          </span>
        </BreakdownItem>
      )}
      {errorMessage && <div className="color-danger">{errorMessage}</div>}
    </SubscriptionPaymentElementWrapper>
  );
}

export default connect((state) => ({
  billingFormIsInvalid: isInvalid('billindAddressForm')(state)
}))(SubscriptionPaymentElement);
