import React from 'react';
import { getTranslationKey } from '../../../modules/utils/helpers';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../../modules/selectors/user';
import { getHTMLFromText } from '../../../helpers/html';
import { getPaymentInfo } from '../../../modules/selectors/paymentInfo';
import Subscriptions from './components/subscriptions';
import BillingInfo from './components/billingInfo';
import RedeemDiscountKey from '../../../elements/DiscountKey/RedeemDiscountKey';
import useTrackClick from '../../../hooks/tracking/use-track-click';

const StyledTable = styled.table`
  && {
    width: 100%;
    th {
      padding-top: 15px;
      font-weight: initial;
      &:last-child {
        text-align: end;
      }
    }
    td:last-child {
      text-align: end;
    }
  }
`;

export const StyledTr = styled.tr<{ disableLine?: boolean }>`
  border-bottom: ${({ disableLine }) => (!disableLine ? '1px solid lightgray' : '')};
`;

export const StyledTd = styled.td<{ paddingTop?: number; align?: string; textDecoration?: string }>`
  padding-top: ${({ paddingTop }) => (paddingTop !== undefined ? paddingTop + 'px' : '15px')};
  text-align: ${({ align }) => (align ? `${align} !important` : 'initial')};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? `${textDecoration} !important` : 'none'};
`;

const UserPlan = styled.span`
  text-transform: capitalize;
  font-weight: 700;
`;

const Overview = () => {
  const trackClick = useTrackClick();
  const { subscription = {}, digistore = {} } = useSelector(getUser);
  const { subscriptions, address, paymentProvider } = useSelector(getPaymentInfo);

  const isFreePlan = ['free', 'freemium', 'free-trial'].includes(subscription.plan);
  const isNewCustomer = isFreePlan && !digistore.order_id;

  const onEditPlanLinkClick = () => trackClick('edit-plan');
  const onContactSupportClick = () => trackClick('contact-support');

  return (
    <StyledTable>
      <thead>
        <tr>
          <th>
            {getHTMLFromText(getTranslationKey('overviewTab.currentPlan'))}{' '}
            <UserPlan>{subscription.plan}</UserPlan>
          </th>
          <th>
            <Link to="/subscription/pricing" onClick={onEditPlanLinkClick}>
              {getTranslationKey('overviewTab.editPlan')}
            </Link>
          </th>
        </tr>
      </thead>

      <tbody>
        <StyledTr>
          <StyledTd>{getTranslationKey('overviewTab.item')}</StyledTd>
          <StyledTd>{getTranslationKey('overviewTab.price')}</StyledTd>
        </StyledTr>

        <Subscriptions
          plan={subscription.plan}
          subscriptions={subscriptions}
          paymentProvider={paymentProvider}
        />
        <BillingInfo plan={subscription.plan} address={address} />

        <StyledTr disableLine={!isNewCustomer}>
          <StyledTd>{getHTMLFromText(getTranslationKey('overviewTab.help'))}</StyledTd>
          <StyledTd>
            <a
              href="https://scrappbook.de/support"
              onClick={onContactSupportClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getTranslationKey('overviewTab.support')}
            </a>
          </StyledTd>
        </StyledTr>

        {isNewCustomer && (
          <StyledTr disableLine>
            <StyledTd>
              <RedeemDiscountKey />
            </StyledTd>
            <StyledTd />
          </StyledTr>
        )}
      </tbody>
    </StyledTable>
  );
};

export default Overview;
