import { IFetchSlideshows, ICreateSlideshowParams, ISetFocalPointParams } from './../../types/API';
import { HTTPClient as HTTPClientCore, IRequestConfig } from '../../../modules/utils';
import axios from 'axios';
import qs from 'qs';

import { CANCEL } from 'redux-saga';
import {
  IAbortMultipartUploadParams,
  IChangeGalleriesOrderParams,
  ICheckS3ImageExists,
  ICollectionIDParam,
  ICompleteMultipartUploadParams,
  ICreateGalleryParams,
  ICreateGlobalShopSettingsParams,
  ICreatePayoutAccountParams,
  ICreatePresetParams,
  ICreateProductPriceListParams,
  IDeleteCollectionCustomDomain,
  IDeleteGalleryParam,
  IDeleteImageParams,
  IDeleteImagesParams,
  IDeletePresetParams,
  IDeleteProductPriceListParams,
  IDeleteVideoParams,
  IDictionary,
  IFetchCollectionParams,
  IFetchOrderListForUserParams,
  IFetchProductUserPricingParams,
  IFetchStatisticsGraphParams,
  IFetchStatisticsOverallParams,
  IFetchStatisticsParams,
  IGalleryParam,
  IGetPinParams,
  IGetSighUrlParams,
  IGetVideoSighUrlParams,
  IMoveImagesParams,
  IMultipleOrderImagesParams,
  IOrderImagesParams,
  IRequestPayoutAction,
  ISearchEndCustomerByEmailParams,
  ISetDefaultPresetParams,
  ISetImageTitleParams,
  IShopCoupon,
  IToggleImageWatermarkParams,
  IToggleWatermarkParams,
  IUpdateCollectionByPathParams,
  IUpdateGalleryParams,
  IUpdateGlobalShopSettingsParams,
  IUpdateMultipleGalleriesParams,
  IUpdatePayoutAccountParams,
  IUpdatePriceValue,
  IUpdateVideoStatusParams,
  IUploadAppIconParams,
  IWatermarksProcessingParams,
  IUpdateFavoritesMusic,
  IRemoveSlideshow,
  ITrackEventParams,
  IImageDimensions,
  ISearchCollectionsAction,
  ISalesConfigBulkUpdateAction,
  ICancelOrderParams,
  IPreUploadHeaderImageParams,
  IDeleteHeaderImageParams,
  IGalleriesTagsAddParams,
  IGalleriesTagsRemoveParams,
  IShopPayDocumentsAction,
  IMainCustomersUpdateAction
} from '../../../modules/types';
import { ISelectionCleanUpParams } from '../../../modules/types/selections';
import { IPreset } from '../../../modules/reducers/presets';
import {
  IEmailTemplatesCreateParams,
  IFetchEmailTemplatesParams,
  ISendEmailsParams
} from '../../../modules/types/emails';
import {
  ICreateDigitalPricingList,
  ICreatePackage,
  IDeleteDigitalPricingList,
  IDeletePackage,
  IGetDigitalPricingListPackages,
  IUpdateDigitalPricingList,
  IUpdatePackage
} from '../../../modules/types/digital-pricing';
import { IBuySubscriptionProps, SubscriptionType } from '../../../modules/types/subscriptions';
import { ICancelPayload, IUpdateBillingAddress } from '../../types/subscriptions';
import {
  ICreateMessageParams,
  IInitiateMessageImageUploadParams,
  IUpdateMessageParams
} from '../../types/message';
import { IEndCustomerProps } from '../../types/endCustomer';
import {
  ICalculateDiscountParams,
  IGetProrationsParams,
  IIniateSubscriptionParams,
  ISetupIntentFailed,
  IUpdateAddonsParams,
  IUpdateSubscriptionParams
} from '../../types/subscription';
import {
  IFetchPortfoliosParams,
  IPortfolioDeleteTitleImageParams,
  IPortfolioIdParams,
  IPortfolioUploadImageParams,
  ISavePortfolioParams,
  IUpdatePortfolioParams
} from '../../types/portfolios';
import moment from 'moment';

class HTTPClient extends HTTPClientCore {
  /**
   * Overridden method adds CancelToken symbol, that allow redux-saga'
   * "takeLatest" function to cancel any requests automatically.
   */
  public makeRequest<T = any>(config: IRequestConfig): Promise<T> {
    const source = axios.CancelToken.source();

    const request: any = super.makeRequest({
      ...config,
      cancelToken: source.token
    });

    request[CANCEL] = () => source.cancel();

    return request;
  }
}

const APIClient = new HTTPClient({
  baseURL: window.API_URL,
  withCredentials: true,
  onCatchNetworkError: (networkError) => {
    if (networkError?.response?.status === 402 && !window.location.href.includes('/subscription')) {
      window.location.href = '/#/subscription';
    }
  }
});

// tslint:disable:no-duplicate-string
export const Api = {
  AccessRules: {
    get: () => APIClient.get(`/api/plan-usage/available-features`),
    getPlanConfig: () => APIClient.get(`/api/subscription/my-plan-config`),
    getPaymentInfo: () => APIClient.get(`/api/subscription/my-payment-info`),
    getPlanUsage: () => APIClient.get(`/api/plan-usage`)
  },
  Collections: {
    get: (props: IFetchCollectionParams) => APIClient.get(`/api/collection`, props),
    deleteDomain: (props: IDeleteCollectionCustomDomain) =>
      APIClient.delete(`/api/site/domain`, props),
    update: (props: IUpdateCollectionByPathParams) =>
      APIClient.put(`/api/collection/update`, props),
    getPin: (props: IGetPinParams) => APIClient.get(`/api/collection/downloadPin`, props),
    uploadAppIcon: (props: IUploadAppIconParams) => APIClient.get(`/api/upload/appIcon`, props),
    search: (props: ISearchCollectionsAction) => APIClient.get(`/api/collections/search`, props),
    deleteMainCustomer: (props: IMainCustomersUpdateAction) =>
      APIClient.delete(`/api/collection/delete-main-customer`, props)
  },
  Portfolios: {
    get: (props: IFetchPortfoliosParams) => APIClient.get(`/api/portfolios`, props),
    create: (data: ISavePortfolioParams) => APIClient.post(`/api/portfolios`, data),
    update: (props: IUpdatePortfolioParams) => APIClient.patch(`/api/portfolios`, props),
    delete: (props: IPortfolioIdParams) => APIClient.delete(`/api/portfolios`, props),
    getSinglePortfolio: (props: IPortfolioIdParams) =>
      APIClient.get(`/api/portfolios/portfolio`, props),
    uploadTitleImage: (props: IPortfolioUploadImageParams) =>
      APIClient.get(`/api/portfolios/upload-title-image`, props),
    deleteTitleImage: (props: IPortfolioDeleteTitleImageParams) =>
      APIClient.delete(`/api/portfolios/delete-title-image`, props)
  },
  Galleries: {
    get: (props: ICollectionIDParam) => APIClient.get(`/api/collection/galleries`, props),
    getById: (props: IGalleryParam) => APIClient.get(`/api/collection/gallery`, props),
    create: (props: ICreateGalleryParams) => APIClient.post(`/api/collection/gallery`, props),
    delete: (props: IDeleteGalleryParam) => APIClient.delete(`/api/collection/gallery`, props),
    update: (props: IUpdateGalleryParams) =>
      APIClient.post(`/api/collection/gallery/update`, props),
    changeOrder: (props: IChangeGalleriesOrderParams) =>
      APIClient.post(`/api/collection/gallery/order`, props),
    toggleWatermark: (props: IToggleWatermarkParams) =>
      APIClient.post(`/api/collection/gallery/toggleWatermark`, props),
    updateMultiple: (props: IUpdateMultipleGalleriesParams) =>
      APIClient.post('/api/collection/galleries/update', props),
    toggleWatermarks: (props: IUpdateMultipleGalleriesParams) =>
      APIClient.post('/api/collection/galleries/toggleWatermark', props),
    getVideoSignUrl: (props: IGetVideoSighUrlParams) =>
      APIClient.get(`/api/upload/video/signedUrl`, props),
    completeMultipartUpload: (props: ICompleteMultipartUploadParams) =>
      APIClient.post(`/api/upload/video/complete-multipart-upload`, props),
    abortMultipartUpload: (props: IAbortMultipartUploadParams) =>
      APIClient.post(`/api/upload/video/abort-multipart-upload`, props),
    updateVideoStatus: (props: IUpdateVideoStatusParams) =>
      APIClient.post(`/api/collection/gallery/video`, props),
    deleteVideo: (props: IDeleteVideoParams) =>
      APIClient.delete(`/api/collection/gallery/video`, props)
  },
  Slideshows: {
    get: (props: Partial<IFetchSlideshows>) =>
      APIClient.get(`/api/slideshows/${props.slideshowId}`),
    create: (props: ICreateSlideshowParams) => APIClient.post(`/api/slideshows`, props),
    update: (props: any, id: string) => APIClient.patch(`/api/slideshows/${id}`, props),
    delete: (props: Partial<IRemoveSlideshow>, id: string) =>
      APIClient.delete(`/api/slideshows/${id}`, props),
    scheduleRender: (id: string) => APIClient.post(`/api/slideshows/${id}/render`),
    cancelRender: (id: string) => APIClient.post(`/api/slideshows/${id}/cancel-render`),
    getRenderServiceStatus: () => APIClient.get(`/api/slideshows/render-service-status`),
    getRenderStatus: (slideshowId: string) =>
      APIClient.get(`/api/slideshows/${slideshowId}/render-status`)
  },
  Music: {
    get: (props: any, value: string) => APIClient.get(`api/music-library/${value}`, props),
    post: (props: IUpdateFavoritesMusic) =>
      APIClient.post('/api/music-library/songs/favorites', props),
    delete: (props: IUpdateFavoritesMusic) =>
      APIClient.delete('/api/music-library/songs/favorites', props)
  },
  Images: {
    get: (props: ICollectionIDParam) => APIClient.get(`/api/images`, props),
    setImageTitle: (props: ISetImageTitleParams) =>
      APIClient.post(`/api/image/setAsTitleImage`, props),
    setFocalPoint: (props: ISetFocalPointParams) =>
      APIClient.patch(`/api/image/${props.image_id}/focal-point`, {
        focalPoint: props.focalPoint,
        screenType: props.screenType
      }),
    delete: (props: IDeleteImageParams) => APIClient.delete(`/api/image`, props),
    deleteByIDs: (props: IDeleteImagesParams) => APIClient.delete(`/api/images`, props),
    moveTo: (props: IMoveImagesParams) => APIClient.post(`/api/images/move`, props),
    order: (props: IOrderImagesParams) => APIClient.post(`/api/images/order`, props),
    multipleOrder: (props: IMultipleOrderImagesParams) =>
      APIClient.post(`/api/images/multipleOrder`, props),
    toggleWatermark: (props: IToggleImageWatermarkParams) =>
      APIClient.post(`/api/image/toggleWatermark`, props),
    getSignUrl: (props: IGetSighUrlParams) => APIClient.get(`/api/upload/image/signedUrl`, props),
    compress: (props: any) => APIClient.post(`/api/image/compress`, props),
    checkS3Exist: (props: ICheckS3ImageExists) =>
      APIClient.get(`/api/image/checkIfExistsOnS3`, props),
    setGifMetaData: (imageId: string, dimensions: IImageDimensions) =>
      APIClient.put(`/api/images/gif/${imageId}/metadata`, dimensions),
    deleteHeaderImage: (imageId: string, props: IDeleteHeaderImageParams) =>
      APIClient.delete(`/api/images/collection-header/${imageId}`, props),
    preUploadHeaderImage: (props: IPreUploadHeaderImageParams) =>
      APIClient.post(`/api/images/collection-header/signed-url`, props)
  },
  Domains: {
    get: () => APIClient.get(`/api/domains`)
  },
  Templates: {
    get: () => APIClient.get(`/api/scrappbookGalleryTemplates`),
    getTempalteFonts: () => APIClient.get(`/api/collection-templates/template-fonts`)
  },
  Selections: {
    get: (props: any) => APIClient.get(`/api/selections/collection`, props),
    cleanUp: (props: ISelectionCleanUpParams, whichImages: string) =>
      APIClient.delete(`/api/selection/cleanup/delete/${whichImages}`, props)
  },
  Statistics: {
    get: (props: IFetchStatisticsParams) => APIClient.get(`/api/statistics/collection`, props),
    getGraph: (props: IFetchStatisticsGraphParams) =>
      APIClient.get(`/api/statistics/collection/graph`, props),
    getOverall: (props: IFetchStatisticsOverallParams) =>
      APIClient.get(`/api/statistics/collection/overall`, props)
  },
  HelpCampaign: {
    back: (props: any) => APIClient.post(`/api/help/campaign/step/`, props)
  },
  Log: {
    useractivitylog: (props: IDictionary<any>) =>
      APIClient.get(`/api/useractivitylog/logaction`, props)
  },
  Watermarks: {
    processing: (props: IWatermarksProcessingParams) =>
      APIClient.get(`/api/watermark/processingStatus`, props)
  },
  Presets: {
    get: () => APIClient.get(`/api/galleryPresets`),
    create: (props: ICreatePresetParams) => APIClient.put(`/api/galleryPreset`, props),
    setDefault: (props: ISetDefaultPresetParams) =>
      APIClient.post(`/api/galleryPreset/setDefault`, props),
    delete: (props: IDeletePresetParams) => APIClient.delete(`/api/galleryPreset`, props),
    update: (props: IPreset) => APIClient.post(`/api/galleryPreset`, props)
  },
  Shop: {
    getProductPricingLists: () => APIClient.get(`/api/productPricingLists`),
    createProductPricingList: (props: ICreateProductPriceListParams) =>
      APIClient.put(`/api/productPricingList`, props),
    deleteProductPricingList: (props: IDeleteProductPriceListParams) =>
      APIClient.delete(`/api/pricing-lists/${props.id}`),
    fetchProductUserPricing: (props: IFetchProductUserPricingParams) =>
      APIClient.get(`/api/productUserPricings`, props),
    updateProductUserPricing: (props: IUpdatePriceValue) =>
      APIClient.patch(`/api/productUserPricing`, props),
    getUserAccountBalance: () => APIClient.get('/api/shop/payment/getAccountBalanceForUser'),
    fetchCouponsList: () => APIClient.get('/api/coupons'),
    createCoupon: (props: Partial<IShopCoupon>) => APIClient.post('/api/coupons', props),
    deleteCoupon: (id: string) => APIClient.delete(`/api/coupons/${id}`),
    updateCoupon: (props: Partial<IShopCoupon>, id: string) =>
      APIClient.patch(`/api/coupons/${id}`, props),
    getProductGroups: () => APIClient.get('/api/products/product-groups'),
    getProducts: () => APIClient.get('/api/products/products'),
    getGlobalShopSettingsForCurrentUser: () =>
      APIClient.get(`/api/shop/global-settings-for-current-user`),
    createGlobalShopSettings: (props: ICreateGlobalShopSettingsParams) =>
      APIClient.post(`/api/shop/global-settings`, props),
    updateGlobalShopSettings: (props: Omit<IUpdateGlobalShopSettingsParams, 'id'>, id: string) =>
      APIClient.patch(`/api/shop/global-settings/${id}`, props),
    fetchOrdersForUser: (props: IFetchOrderListForUserParams) =>
      APIClient.get('/api/orders?' + qs.stringify(props)),
    cancelOrder: (props: ICancelOrderParams) =>
      APIClient.patch(`/api/orders/${props.orderId}/cancel`),
    fetchOrderImagesForUser: (orderId: string) => APIClient.get(`/api/orders/${orderId}/images`),
    fetchLaboratories: () => APIClient.get(`/api/laboratories`),
    requestPayout: (props: IRequestPayoutAction) =>
      APIClient.post(`/api/payout-account/payout-request`, props),
    getDigitalPricingLists: () => APIClient.get(`/api/digital-pricing-list`),
    getDigitalPricingListPackages: (props: IGetDigitalPricingListPackages) =>
      APIClient.get(`/api/digital-pricing-list/${props.pricingListId}/packages`),
    createPackage: (props: ICreatePackage) =>
      APIClient.post(`/api/digital-pricing-list/package`, props),
    deletePackage: (props: IDeletePackage) =>
      APIClient.delete(
        `/api/digital-pricing-list/package/${props.packageId}?pricingListId=${props.pricingListId}`
      ),
    updatePackage: (props: IUpdatePackage) =>
      APIClient.put(`/api/digital-pricing-list/package/${props.packageId}`, props.data),
    createDigitalPricingList: (props: ICreateDigitalPricingList) =>
      APIClient.post(`/api/digital-pricing-list`, { name: props.name }),
    deleteDigitalPricingList: (props: IDeleteDigitalPricingList) =>
      APIClient.delete(`/api/digital-pricing-list/${props.digitalPricingListId}`),
    updateDigitalPricingList: (props: IUpdateDigitalPricingList) =>
      APIClient.put(`/api/digital-pricing-list/${props.digitalPricingListId}`, props.data),
    salesAutomationBulkUpdate: (props: ISalesConfigBulkUpdateAction) =>
      APIClient.patch(`/api/shop/sales-config/bulk`, props),
    fetchPayDocumnets: (props?: IShopPayDocumentsAction) =>
      APIClient.get(`/api/shop/pay-documents`, props),
    userHasEnoughInvoicingData: () => APIClient.get(`/api/shop/user-has-enough-invoicing-data`)
  },
  Countries: {
    get: () => APIClient.get(`/api/countries`)
  },
  User: {
    updateUserByID: (props: any, userID: string) => APIClient.patch(`/api/users/${userID}`, props),
    fetchPayoutAccount: () => APIClient.get(`/api/payout-account`),
    createPayoutAccount: (props: ICreatePayoutAccountParams) =>
      APIClient.post(`/api/payout-account`, props),
    updatePayoutAccount: (props: Omit<IUpdatePayoutAccountParams, 'id'>, id: string) =>
      APIClient.patch(`/api/payout-account/${id}`, props),
    getBuyUrl: (props: IBuySubscriptionProps) =>
      APIClient.get(
        `/api/subscription/buy/scrappbook/${props.linkPath}${props.querySymbol}${
          props.querySymbol ? props.query : ''
        }`
      ),
    cancelSubscription: (props: ICancelPayload) =>
      APIClient.post(`/api/subscription/cancel-subscription`, props),
    addPromotionCodeToSubscription: (couponId: string) =>
      APIClient.patch(`/api/subscription/add-promotion-code`, { couponId }),
    restartSubscription: (props: { subscriptionType: SubscriptionType }) =>
      APIClient.post(`/api/subscription/restart`, props),
    updateBillingAddress: (props: IUpdateBillingAddress) =>
      APIClient.post(`/api/subscription/billing-address`, props),
    getAvailableCurrencies: (country?: string) =>
      APIClient.get(`/api/users/available-currencies?country=${country || ''}`)
  },
  Emails: {
    create: (props: IEmailTemplatesCreateParams) => APIClient.post(`/api/email-templates`, props),
    delete: (id: string) => APIClient.delete(`/api/email-templates/${id}`),
    getPreview: (props: any) => APIClient.get(`/api/email-templates/gallery-link-preview`, props),
    fetchEmailTemplates: (props: IFetchEmailTemplatesParams) =>
      APIClient.get(`/api/email-templates?type=collection-delivery`, props),
    updateEmailTemplate: (props: IEmailTemplatesCreateParams, emailTemplateId: string) =>
      APIClient.patch(`/api/email-templates/${emailTemplateId}`, props),
    sendEmail: (params: ISendEmailsParams) =>
      APIClient.post('/api/emails/collection-link-email', params),
    fetchEmailPackages: () => APIClient.get('/api/sales-automation/email-templates-package'),
    createNewPackage: (title: string) =>
      APIClient.post('/api/sales-automation/email-templates-package', { title }),
    deletePackage: (packageId: string) =>
      APIClient.delete(`/api/sales-automation/email-templates-package/${packageId}`),
    sendLoginLinkEmail: () => APIClient.post(`/api/emails/send-login-link-email`)
  },
  EndCustomer: {
    searchByEmail: (params: ISearchEndCustomerByEmailParams) =>
      APIClient.get('/api/end-customers/search?', params),
    setEndCustomerSubscription: (props: IEndCustomerProps) =>
      APIClient.put(`/api/end-customers/email-subscription`, props)
  },
  Analytics: {
    trackEvent: (params: ITrackEventParams) => APIClient.post('/api/analytics', params)
  },
  Trial: {
    startFreeTrial: () => APIClient.post('/api/subscription/start-trial')
  },
  GalleriesTags: {
    get: () => APIClient.get(`/api/users/gallery-tags`),
    add: (props: IGalleriesTagsAddParams) => APIClient.post(`/api/collections/gallery-tag`, props),
    remove: (props: IGalleriesTagsRemoveParams, tagId: string) =>
      APIClient.delete(`/api/collections/${tagId}/gallery-tag`, props)
  },
  Messages: {
    get: () => APIClient.get(`/api/message`),
    create: (params: ICreateMessageParams) => APIClient.post(`/api/message`, params),
    update: (id: string, params: IUpdateMessageParams) =>
      APIClient.patch(`/api/message/${id}`, params),
    delete: (id: string) => APIClient.delete(`/api/message/${id}`),
    initiateUpload: (id: string, params: IInitiateMessageImageUploadParams) =>
      APIClient.post(`/api/message/${id}/upload`, params)
  },
  Subscription: {
    getPricing: () => APIClient.get('/api/subscription/pricing'),
    getDigistoreBalance: (prorationDateSec: number = moment().unix()) =>
      APIClient.get('/api/subscription/digistore-balance?prorationDateSec=' + prorationDateSec),
    calculateDiscount: (params: ICalculateDiscountParams) =>
      APIClient.get('/api/subscription/calculate-discount?' + qs.stringify(params)),
    initiateNewSubscription: (params: IIniateSubscriptionParams) =>
      APIClient.post('/api/subscription/initiate-new-subscription', params),
    setupIntentFailed: (params: ISetupIntentFailed) =>
      APIClient.post('/api/subscription/setup-intent-failed', params),
    getProrations: (params: IGetProrationsParams) =>
      APIClient.get('/api/subscription/calculate-prorations?' + qs.stringify(params)),
    updateSubscription: (params: IUpdateSubscriptionParams) =>
      APIClient.patch('/api/subscription', params),
    updateAddons: (params: IUpdateAddonsParams) =>
      APIClient.patch('/api/subscription/addons', params)
  }
};

export default Api;
