import React, { ReactNode } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import styled from 'styled-components';
import useModalHelpers from './useModalHelpers';

const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: #5c8762;
  text-decoration: underline;
  font: inherit;
  cursor: pointer;
`;

const StyledModalHeader = styled(ModalHeader)`
  color: #fff;
`;

interface IStepMap {
  [key: number]: ReactNode;
}

const stepMap: IStepMap = {
  1: <FirstStep />,
  2: <SecondStep />,
  3: <ThirdStep />,
  4: <FourthStep />
};

const CancelSubscriptionModal = () => {
  const { step, isOpen, goNextStep, closeModal, activeRadio, isApplyingOffer } = useModalHelpers();

  return (
    <Modal centered isOpen={isApplyingOffer ? true : isOpen} size={step === 4 ? 'lg' : 'md'}>
      <StyledModalHeader toggle={isApplyingOffer ? null : closeModal}>
        {step === 4
          ? getTranslationKey('cancelSubscriptionModal.discount.modalTitle')
          : getTranslationKey('cancelSubscriptionModal.title')}
      </StyledModalHeader>

      <ModalBody>{stepMap[step]}</ModalBody>

      {step !== 4 ? (
        <ModalFooter>
          <Button
            size="md"
            color="success"
            onClick={goNextStep}
            className="btn btn-primary"
            disabled={!activeRadio.length}
          >
            {getTranslationKey('cancelSubscriptionModal.nextButton')}
          </Button>
        </ModalFooter>
      ) : (
        <div className="w-100 text-center m-b-10">
          <LinkButton
            onClick={() => {
              if (isApplyingOffer) return;

              goNextStep();
            }}
            className="cursor-pointer"
          >
            {getTranslationKey('cancelSubscriptionModal.discount.continueCancellationButton')}
          </LinkButton>
        </div>
      )}
    </Modal>
  );
};

export default CancelSubscriptionModal;
